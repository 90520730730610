<form [formGroup]="selectedSuggestionFormGroup">
  <p-autoComplete
    #autocomplete
    class="search-input"
    optionLabel="address"
    optionDisabled="disabled"
    formControlName="suggestion"
    [placeholder]="'SEARCH_LOCATION_INPUT.PLACEHOLDER' | translate"
    [emptyMessage]="'SEARCH_LOCATION_INPUT.NO_RESULTS' | translate"
    [showEmptyMessage]="true"
    [style]="{ width: '100%' }"
    [showEmptyMessage]="true"
    [inputStyle]="{ width: '100%' }"
    [suggestions]="(locationSuggestions$ | async) ?? []"
    [completeOnFocus]="true"
    (completeMethod)="onSearchLocation($event)"
    (onSelect)="onSelectLocation($event)"
    (onFocus)="onAutocompleteFocus()"
  >
    <ng-template let-location pTemplate="item">
      <div *ngIf="location.isCurrentLocation; else default" class="current-location-suggestion flex-row">
        <app-icon icon="target"></app-icon>
        <p class="full-width">{{ location.address }}</p>
        <app-icon class="checked" icon="check"></app-icon>
      </div>

      <ng-template #default>
        <div class="location-suggestion flex-row">
          <p class="full-width">{{ location.address }}</p>
          <app-icon class="checked" icon="check"></app-icon>
        </div>
      </ng-template>
    </ng-template>
  </p-autoComplete>
</form>
